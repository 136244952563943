import "./TestimonialsCardComponent.scss"
import "react-lazy-load-image-component/src/effects/blur.css"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
const TestimonialsCardComponent = ({
  imgUrl,
  click,
  id,
  testimonial,
  designerName,
  profileUrl,
  designationText,
  isTraveler,
}) => {
  const customNavigateTo = url => {
    window.open(url, "_blank")
  }
  return (
    <div
      className="testimonial-card"
      style={{
        padding: "10px",
      }}
    >
      <div className="media-content">
        <div
          className="content"
          style={{ backgroundColor: "white !important" }}
        >
          <div className="testimonial-quote group">
            <div className="quote-container">
              <blockquote style={{ backgroundColor: "white !important" }}>
                <p
                  style={{ textAlign: "center", textShadow: "none !important" }}
                >
                  {testimonial}
                  {/* When creating trips for my clients, one of my favorite
                  features was creating and saving a “bank” of my favorite sites
                  and experiences that I can use send to my clients. I can also
                  directly link reservations, websites, menus, etc., for easy
                  access when abroad.” */}
                </p>
              </blockquote>
            </div>
            {/* <br></br> */}
            {isTraveler ? (
              <div
                style={{
                  width: "94px",
                  margin: "0px auto",
                  marginTop: "20px",
                  // cursor: "pointer",
                }}
                onClick={() => {
                  // customNavigateTo(profileUrl)
                }}
              >
                {/* <figure className="testimonial-profile-pic"> */}
                <LazyLoadImage
                  src={imgUrl}
                  style={{
                    borderRadius: "50%",
                    width: "94px",
                    height: "94px",
                    padding: "2px",
                    border: "3px solid #fec600",
                  }}
                  alt="testimonial-profile-image"
                  width="94px"
                  height="94px"
                  effect="blur"
                ></LazyLoadImage>
                {/* </figure> */}
              </div>
            ) : (
              <div
                style={{
                  width: "84px",
                  margin: "0px auto",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  customNavigateTo(profileUrl)
                }}
              >
                {/* <figure className="testimonial-profile-pic"> */}
                <LazyLoadImage
                  src={imgUrl}
                  style={{
                    borderRadius: "50%",
                    width: "84px",
                    height: "84px",
                    padding: "2px",
                    border: "3px solid #fec600",
                  }}
                  alt="testimonial-profile-image"
                  width="84px"
                  height="84px"
                  effect="blur"
                ></LazyLoadImage>
                {/* </figure> */}
              </div>
            )}
            <div
              style={{
                margin: "7px 0px",
                textAlign: "center",
              }}
            >
              {isTraveler ? (
                <p
                  className="testimonial-designerName cursor-default"
                  // onClick={() => {
                  //   customNavigateTo(profileUrl)
                  // }}
                >
                  {designerName}
                </p>
              ) : (
                <p
                  className="testimonial-designerName"
                  onClick={() => {
                    customNavigateTo(profileUrl)
                  }}
                >
                  {designerName}
                </p>
              )}

              {designationText ? (
                <div
                  style={{
                    lineHeight: "10px",
                  }}
                >
                  <small>{designationText}</small>
                </div>
              ) : (
                <div
                  style={{
                    lineHeight: "10px",
                  }}
                >
                  <small>
                    Travel designer on{" "}
                    <strong
                      style={{
                        // fontSize: "18px",
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                      }}
                    >
                      {" "}
                      voyayge
                    </strong>{" "}
                  </small>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsCardComponent
