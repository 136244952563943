// import "./landingpage.scss"
import { Link, navigate } from "gatsby"
import loadable from "@loadable/component"
// import { gsap } from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
import is from "is_js"
import { CarouselProvider, Dot, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, { useContext, useEffect, useRef, useState } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/black-and-white.css"
import "react-lazy-load-image-component/src/effects/blur.css"
import designTools from "../../images/design-tools.png"
import paid from "../../images/paid.png"
import profileStandout from "../../images/profile-standout.png"
import wiggle from "../../images/wiggle.svg"
import Accordion from "../Accordion/Accordion"
import { analytics } from "../firebase/firebase"
import Image from "../image"
import { AuthUserContext } from "../session"
import TestimonialsCardComponent from "../TestimonialsCardComponent/TestimonialsCardComponent"
import { FirebaseContext } from "./../../components/firebase"
import * as ROUTES from "./../../constants/routes"
import logoSVG from "./../../images/logo.png"
import "./BecomeADesigner.scss"
const FetchAsyncDesignerCards = loadable(() =>
  import("../DesignerCards/FetchAsyncDesignerCards")
)
let backgroundPosition = is.mobile() ? Math.floor(Math.random() * 100) : 55

const BecomeADesigner = () => {
  const [atTop, setAtTop] = useState(true)
  const [state, setState] = useState("none")
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const authContext = useContext(AuthUserContext)
  const dbContext = useContext(FirebaseContext)
  const [newsletterEmailError, setNewsletterEmailError] = useState(false)
  const [newsletterEmail, setNewsletterEmail] = useState("")
  const [newsletterEmailLoading, setNewsletterEmailLoading] = useState(false)
  const [
    newsletterSubscriptionState,
    setNewsletterSubscriptionState,
  ] = useState("default")

  useEffect(() => {
    document.addEventListener("scroll", positionSetter)
    return () => window.removeEventListener("scroll", positionSetter)
  }, [])
  const positionSetter = e => {
    if (window.scrollY === 0) {
      //console.log("Scroll reached top")
      setAtTop(true)
    } else {
      setAtTop(false)
    }
  }
  useEffect(() => {
    let tl

    // if (typeof window !== "undefined") {
    //   gsap.registerPlugin(ScrollTrigger)
    //   tl = gsap.timeline({ delay: 0.2 })
    // }
    // tl.to(scrollRef, {
    //   scrollTrigger: {
    //     trigger: scrollRefStart,
    //     endTrigger: scrollRef,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "0px",
    //     end: "100%",
    //     // markers: true,
    //   },
    //   opacity: 0,

    //   ease: "power4.out",
    //   duration: 3,
    // })

    // tl.from(designerRef1, {
    //   scrollTrigger: {
    //     trigger: trigger2,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "top center",
    //     //markers: true,
    //   },
    //   opacity: 0,
    //   x: -20,

    //   ease: "power4.out",
    //   duration: 3,
    // })
    // tl.from(designerRef2, {
    //   scrollTrigger: {
    //     trigger: trigger2,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "top center",
    //     //markers: true,
    //   },
    //   opacity: 0,
    //   //x: 10,

    //   ease: "power4.out",
    //   duration: 3,
    // })
    // tl.from(designerRef3, {
    //   scrollTrigger: {
    //     trigger: trigger2,
    //     toggleActions: "restart none reverse pause",
    //     scrub: true,
    //     start: "top center",
    //     //markers: true,
    //   },
    //   opacity: 0,
    //   x: 20,

    //   ease: "power4.out",
    //   duration: 3,
    // })
    // tl.utils.toArray(".section").forEach((panel, i) => {
    //   ScrollTrigger.create({
    //     trigger: panel,
    //     start: "top top",
    //     pin: true,
    //     pinSpacing: false,
    //   })
    // })
  }, [])
  const faqWrapper = qNa => {
    return (
      // <div className="columns is-tablet is-multiline">
      //   <div className="column is-6-desktop is-6-tablet">
      //     <div className="columns is-tablet is-multiline">
      //       <div className="column is-12-desktop is-12-tablet">{firstPair}</div>
      //     </div>
      //   </div>
      //   <div className="column is-6-desktop is-6-tablet">
      //     <div className="columns is-tablet is-multiline">
      //       <div className="column is-12-desktop is-12-tablet">
      //         {secondPair}
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>{qNa}</div>
    )
  }

  var scrollRef = useRef(null)
  var scrollRefStart = useRef(null)
  var logoRef = useRef(null)
  var travelerDetailsRef = useRef(null)
  var priceCardRef = useRef(null)
  var trigger1 = useRef(null)
  var trigger2 = useRef(null)
  var designerRef1 = useRef(null)
  var designerRef2 = useRef(null)
  var designerRef3 = useRef(null)

  return (
    <>
      {/* <ProgressBar height="10" bgcolor="#fec600" duration="1" /> */}
      <div ref={e => (scrollRefStart = e)}></div>
      <div
        className="scroll-indicator"
        id="scroll-indicator"
        ref={e => (scrollRef = e)}
        style={{
          display: "block",
          width: "20px",
          height: "50px",
          position: "absolute",
          bottom: "50px",
          left: "50%",
          marginLeft: "-10px",
          color: "#fec600",
        }}
      >
        {/* <p style={{ textAlign: "center" }}>Scroll Down</p> */}
        <i className="fal fa-angle-double-down fa-3x"></i>
        {/* <img src={scroll} alt="scroll-down" /> */}
      </div>
      <section
        // className="section is-large map-background"
        className="section"
        style={{ minHeight: "100vh" }}
      >
        <Image
          src="worldMap"
          style={{ position: "unset" }}
          // className="gatsby-hero-image"
          // fluid={data1.image.childImageSharp.fluid}
          alt="voyayge hero image"
        ></Image>

        <div className="hero-body center">
          <div
            className="has-text-centered hero-container"
            ref={e => (logoRef = e)}
            style={{
              opacity: "1",
              overflow: "hidden",
              maxWidth: "1040px",
              textAlign: "left",
              margin: "0 auto",
            }}
          >
            <div
              className="hero-logo-small"
              style={{
                textAlign: "left",
                // maxWidth: "150px",
                //marginTop: "26px",
                // margin: "0 auto",
                marginBottom: "40px",
              }}
            >
              <LazyLoadImage
                src={logoSVG}
                alt="voyayge company logo"
                style={{ opacity: "1" }}
                // width="750px"
                // height="170px"
                effect="blur"
              />
            </div>
            {/* <p className="hero-caption">GET YOUR PERSONALIZED TRAVEL PLAN</p> */}
            <h1
              className="hero-caption"
              style={{
                // textTransform: "uppercase",
                color: "rgba(74,74,74)",
                lineHeight: "1",
                // fontSize: "48px",
                // letterSpacing: "0.5px",
                // fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {/* Get your personalized travel plan crafted! */}
              {/* Democratizing */} If travel is all you think about &
              <span
                className="yay"
                style={{
                  color: "#fec600",
                  // fontFamily: "Montserrat",
                  // fontSize: "84px",
                }}
              >
                {" "}
                love{" "}
              </span>{" "}
              travel planning
              <span style={{ color: "#ffc600" }}>.</span>
              <span
                // className="gradient-underline"
                style={{
                  textDecorationColor: "#fec600",
                  textDecorationLine: "underline",

                  // textDecorationStyle: "wavy",
                }}
              ></span>{" "}
            </h1>

            <p
              className="hero-caption-2"
              style={{ textTransform: "", color: "rgba(74,74,74)" }}
            >
              Then monetize this passion by{" "}
              <span
                // className="gradient-underline"
                style={
                  {
                    // textDecorationColor: "#fec600",
                    // textDecorationLine: "underline",
                    // textDecorationThickness: "3px",
                    // textDecorationStyle: "wavy",
                  }
                }
              >
                crafting travel plans
              </span>{" "}
              {/* on{" "}
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  // fontFamily: "Montserrat",
                }}
              >
                {" "}
                voyay!ge
              </span>{" "} */}
              as a travel designer
              {/* <strong
                style={{
                  // fontSize: "20px",
                  fontWeight: "700",
                  fontFamily: "Montserrat",
                }}
              >
                {" "}
                voyay!ge
              </strong>{" "} */}
              <span style={{ color: "" }}>.</span>
              {/* Get your personalized travel plan crafted! */}
              {/* Democratizing bespoke travel planning. */}
              {/* Platform for bespoke travel itineraries */}
            </p>
            <div
              style={{
                // maxWidth: "250px",
                // margin: "30px auto",
                marginTop: "15px",
              }}
              className="hero-button-container"
            >
              <button
                // className="button is-medium is-warning is-inverted brand-shadow"
                className="button is-warning brand-shadow"
                style={{
                  // width: "100%",
                  fontWeight: "600",
                  letterSpacing: "0.5px",
                }}
                onClick={e => {
                  if (authContext) navigate("/app/dashboard")
                  else {
                    analytics.logEvent("hero_signup_designer_click")
                    navigate("/signup-as-designer")
                  }
                }}
              >
                Join today
              </button>
            </div>
            {/* <div
              style={{
                // maxWidth: "250px",
                // margin: "30px auto",
                marginTop: "10px",
              }}
              // className="hero-button-container"
            >

              <p
                // className="button is-medium is-warning is-inverted brand-shadow"
                className=""
                style={{
                  width: "100%",
                  textAlign: "left",
                  textDecoration: "underline",
                  textDecorationColor: "#fec600",
                  textDecorationThickness: "3px",
                  fontSize: "18px",
                  fontWeight: "600",
                  cursor: "pointer",
                }}
                onClick={e => {
                  analytics.logEvent("hero_signup_click")
                  if (authContext) navigate("/app/dashboard")
                  else navigate("/signup-as-traveler")
                }}
              >
                Get started to experience the difference{" "}
                <i
                  className="fas fa-long-arrow-right"
                  style={{
                    color: "#fec600",
                    marginLeft: "5px",
                    marginTop: "2px",
                  }}
                ></i>
              </p>
            </div> */}
            {/* <button className="buttonis-warning">Get Started</button> */}
          </div>
        </div>
        <span
          className="scroll-btn"
          ref={e => (scrollRef = e)}
          style={{ position: "absolute", bottom: "10px" }}
        >
          <a href="#become-designer">
            <i
              style={{ color: "#fec600" }}
              className="fal fa-angle-double-down fa-3x"
            ></i>
            {/* <span className="mouse">
              <span></span>
            </span>{" "} */}
            {/* <p style={{ textAlign: "center" }}>Scroll Down</p> */}
          </a>
        </span>
      </section>

      <div
        style={{
          position: "relative",
        }}
      >
        <section
          // className="section is-medium gradient"
          className="section is-medium"
          style={{
            borderRadius: "0px",
            height: "auto",
            paddingLeft: "0px",
            paddingRight: "0px",
            // border: ".2px solid #fec600",
            // backgroundColor: "#BCBCBC",
          }}
          // id="become-designer"
          ref={e => (trigger2 = e)}
        >
          <div className="container">
            <h2 className="section-header" id="become-designer">
              <span
                style={{
                  color: "#4a4a4a",
                }}
              >
                BECOME A <br></br> TRAVEL DESIGNER
              </span>
              <span
                style={{
                  color: "#fec600",
                }}
              >
                .
              </span>
              {/* Become a <br></br> travel designer */}
            </h2>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "1.8",
                marginTop: "15px",
              }}
            >
              Is traveling your passion? Have you acquired unique local
              knowledge over the years and loves performing extensive research
              to plan trips? Then use your travel wisdom and love for planning
              to help craft personalized itineraries for other travelers and
              make money!
              <br></br> <br></br>
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "1.7",
                  marginTop: "15px",
                  // textTransform: "uppercase",
                }}
              >
                Here's how it works:
              </span>
              <br />
              <br />
            </p>
            <div className="columns">
              <div
                className="column"
                ref={e => (designerRef1 = e)}
                style={{ textAlign: "center" }}
              >
                {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>Traveler</p> */}
                {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>1</p> */}
                {/* <div style={{ maxWidth: "130px", margin: "0 auto" }}>
                  <img src={one} alt="" />
                </div> */}
                <div
                  style={{
                    width: "100px",
                    height: "111px",
                    margin: "0 auto",
                    marginTop: "50px",
                    marginBottom: "20px",
                  }}
                >
                  <LazyLoadImage
                    src={profileStandout}
                    width="100px"
                    height="111px"
                    alt="build travel designer profile"
                    effect="blur"
                  />
                </div>
                <h4
                  style={{
                    fontSize: "20px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                  className={"gradient-card"}
                >
                  <span
                    style={{
                      fontSize: "18px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    1. Build your profile
                  </span>{" "}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "10px",
                    lineHeight: "1.8",
                  }}
                >
                  {" "}
                  We'll take you through the process of creating a profile that
                  provides an insightful overview of your skills.
                </p>
              </div>
              <div
                className="column"
                ref={e => (designerRef2 = e)}
                style={{ textAlign: "center" }}
              >
                {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>2</p> */}
                {/* <div style={{ maxWidth: "130px", margin: "0 auto" }}>
                  <img src={two} alt="" />
                </div> */}
                <div
                  style={{
                    width: "100px",
                    height: "111px",
                    margin: "0 auto",
                    marginTop: "50px",
                    marginBottom: "20px",
                  }}
                >
                  <LazyLoadImage
                    style={{
                      marginTop: "20px",
                    }}
                    width="100px"
                    height="111px"
                    src={designTools}
                    alt="design a bespoke travel plan"
                    effect="blur"
                  />
                </div>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                  className={"gradient-card"}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    2. Craft travel itineraries
                  </span>{" "}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    marginBottom: "10px",
                    lineHeight: "1.8",
                  }}
                >
                  {" "}
                  Craft a customized travel itinerary based on customer
                  requirements. We have built the tools required to help you
                  with this.
                </p>
              </div>
              <div
                className="column"
                ref={e => (designerRef3 = e)}
                style={{ textAlign: "center" }}
              >
                {/* <p style={{ fontSize: "65px", fontWeight: "300" }}>3</p> */}
                {/* <div style={{ maxWidth: "130px", margin: "0 auto" }}>
                  <img src={three} alt="" />
                </div> */}
                <div
                  style={{
                    width: "100px",
                    height: "111px",
                    margin: "0 auto",
                    marginTop: "50px",
                    marginBottom: "20px",
                  }}
                >
                  <LazyLoadImage
                    style={{
                      marginTop: "20px",
                    }}
                    width="100px"
                    height="111px"
                    src={paid}
                    alt="get paid for creating travel plans"
                    effect="blur"
                  />
                </div>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    marginBottom: "10px",
                  }}
                  className={"gradient-card"}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "800",
                      textTransform: "uppercase",
                      letterSpacing: "0.5px",
                    }}
                  >
                    3. Get paid
                  </span>{" "}
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "1.8",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  Our pricing is simple & transparent. There is no listing or
                  transaction fee and you keep <strong>75%</strong> of the
                  itinerary design fee which is{" "}
                  <Link
                    style={{
                      cursor: "pointer",
                      fontWeight: "600",
                      color: "#4a4a4a",
                    }}
                    className="link"
                    to="/#our-approach"
                  >
                    $25/day
                  </Link>
                </p>
              </div>
            </div>
            <div
              style={{
                maxWidth: "400px",
                margin: "30px auto",
                marginTop: "40px",
              }}
            >
              {/* <div style={{ padding: "20px" }}>
              <img
                src={card}
                alt="card"
                style={{ filter: "brightness(1.1)" }}
              />
            </div>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                lineHeight: "1.7",
              }}
              className={"gradient-card"}
            >
              For a limited time, the first 20 users that join the platform as a
              travel designer will receive a $50 gift card upon successful
              onboarding.
            </p> */}
              <button
                className="button is-medium is-warning"
                style={{ width: "100%", marginTop: "0px" }}
                onClick={e => {
                  const authUser = localStorage.getItem("authUser")
                  if (authUser) {
                    // If logged in, redirect to the App home page.
                    navigate(ROUTES.DESIGNER_SIGNUP)
                  } else navigate(ROUTES.SIGNUP_AS_DESIGNER)
                  // } else navigate(ROUTES.DESIGNER_INVITE)
                }}
              >
                Join as a travel designer
              </button>
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "7px",
                  textAlign: "center",
                }}
              >
                Joining voyayge as a travel designer is free.
              </p>
            </div>
          </div>

          <div
            className="container"
            style={{
              // maxWidth: "800px",
              margin: "30px auto",
              marginTop: "40px",
              // background: "#fec600",
            }}
          >
            <div
              style={{
                maxWidth: "75px",
                margin: "0px auto",
                marginBottom: "40px",
              }}
            >
              <img src={wiggle} alt="" />
            </div>

            <div style={{ maxWidth: "300px", margin: "20px auto" }}>
              <Image src="traveldesigner" alt="travel designer image"></Image>
            </div>
            <div
              style={{
                maxWidth: "270px",
                margin: "0px auto",
                marginBottom: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  letterSpacing: "1px",
                  color: "rgba(14,19,24,.45)",
                  textAlign: "center",
                  marginTop: "40px",
                }}
              >
                WHAT DESIGNERS SAY ABOUT US
              </p>
            </div>
            <div className="columns">
              {/* <div className="column is-1-desktop"></div> */}
              <div className="column is-6-desktop">
                <TestimonialsCardComponent
                  imgUrl={
                    "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2Fmhn2NU9a4Ub9i05oF85FF9B4JIE3%2Fprofile%2FprofileImage.png?alt=media&token=e594e447-0227-4fe4-bead-41d5d6c674de"
                  }
                  testimonial={
                    "When creating trips for my clients, one of my favorite features was creating and saving a “bank” of my favorite sites and experiences that I can curate for my clients. I can also directly link reservations, websites, menus, etc., for easy access when abroad."
                  }
                  designerName={"Lauren Bebe"}
                  profileUrl={"/app/userprofiles/mhn2NU9a4Ub9i05oF85FF9B4JIE3"}
                />
              </div>

              <div className="column is-6-desktop">
                <TestimonialsCardComponent
                  imgUrl={
                    "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2FZEHZQtK4QhSYGcFB1udgnwbXgaE2%2Fprofile%2FprofileImage.png?alt=media&token=6c64ce0d-9026-4b56-a8a8-d79d3c3db71e"
                  }
                  testimonial={`voyayge has been a fantastic platform for me to work with travelers to create custom itineraries. I can track every traveler's preferences in the group and curate unique experiences for each of them. It's beneficial when kids are a part of the group. The platform makes it simple without too much back and forth and helps in creating customized itineraries faster. The final itinerary allows me to give tons of information about each destination, tour, and experience with tips and website links.`}
                  designerName={"Shilpa Kulkarni"}
                  profileUrl={"/app/userprofiles/ZEHZQtK4QhSYGcFB1udgnwbXgaE2"}
                />
              </div>
              {/* <div className="column is-1-desktop"></div> */}
            </div>

            {false && (
              <CarouselProvider
                // naturalSlideWidth={100}
                naturalSlideHeight={125}
                totalSlides={2}
                isPlaying={true}
                infinite={true}
                visibleSlides={1}
                isIntrinsicHeight={true}
                // currentSlide={0}
              >
                <Slider>
                  <Slide index={0}>
                    <TestimonialsCardComponent
                      imgUrl={
                        "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2Fmhn2NU9a4Ub9i05oF85FF9B4JIE3%2Fprofile%2FprofileImage.png?alt=media&token=e594e447-0227-4fe4-bead-41d5d6c674de"
                      }
                      testimonial={
                        "When creating trips for my clients, one of my favorite features was creating and saving a “bank” of my favorite sites and experiences that I can use send to my clients. I can also directly link reservations, websites, menus, etc., for easy access when abroad."
                      }
                      designerName={"Lauren Bebe"}
                      profileUrl={
                        "https://localhost:8000/app/userprofiles/mhn2NU9a4Ub9i05oF85FF9B4JIE3"
                      }
                    />
                  </Slide>

                  <Slide index={1}>
                    <TestimonialsCardComponent
                      imgUrl={
                        "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2FZEHZQtK4QhSYGcFB1udgnwbXgaE2%2Fprofile%2FprofileImage.png?alt=media&token=6c64ce0d-9026-4b56-a8a8-d79d3c3db71e"
                      }
                      testimonial={
                        "voyayge has been a fantastic platform for me to work with travelers to create custom itineraries. I can track every traveler's preferences in the group and curate unique experiences for each of them. It's beneficial when kids are a part of the group. The platform makes it simple without too much back and forth and helps in creating customized itineraries faster. The final itinerary allows me to give tons of information about each destination, tour, and experience with tips and website links."
                      }
                      designerName={"Shilpa Kulkarni"}
                      profileUrl={
                        "https://localhost:8000/app/userprofiles/ZEHZQtK4QhSYGcFB1udgnwbXgaE2"
                      }
                    />
                  </Slide>
                  {/* <Slide index={2}>
                  {" "}
                  <TestimonialsCardComponent
                    imgUrl={
                      "https://firebasestorage.googleapis.com/v0/b/voyayge-dev-9adfe.appspot.com/o/user-data%2FCaQ3tlqOl4VeYLlfbBQHlqmZWHI3%2Fprofile%2FprofileImage.png?alt=media&token=1402b5d4-f23e-4ac3-81d2-ee6fc0f790d4"
                    }
                    testimonial={
                      "When creating trips for my clients, one of my favorite features was creating and saving a “bank” of my favorite sites and experiences that I can use send to my clients. I can also directly link reservations, websites, menus, etc., for easy access when abroad."
                    }
                    designerName={"Carine Carringnon"}
                    profileUrl={
                      "https://localhost:8000/app/userprofiles/CaQ3tlqOl4VeYLlfbBQHlqmZWHI3"
                    }
                  />
                </Slide> */}
                  {/* <Slide index={3}>
                  <FeaturedCardComponent
                    id="3"
                    click={featuredCardClickHandler}
                    title="Paris"
                    imgUrl={
                      "https://images.unsplash.com/photo-1502602898657-3e91760cbb34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80"
                    }
                  />
                </Slide>
                <Slide index={4}>
                  <FeaturedCardComponent
                    id="4"
                    click={featuredCardClickHandler}
                    title="India"
                    imgUrl={
                      "https://images.unsplash.com/photo-1564507592333-c60657eea523?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1951&q=80"
                    }
                  />
                </Slide> */}
                </Slider>
                {/* <Button.Group size={3}>
                {[...Array(slides).keys()].map(slide => (
                  <button as={Dot} key={slide} icon="circle" slide={slide} />
                ))}
              </Button.Group> */}
                <div style={{ maxWidth: "70px", margin: "0px auto" }}>
                  <Dot className="dots" slide={0}>
                    .
                  </Dot>
                  <Dot className="dots" slide={1}>
                    .
                  </Dot>
                  {/* <Dot className="dots" slide={2}>
                  .
                </Dot> */}
                </div>
              </CarouselProvider>
            )}
          </div>

          <div
            className="container"
            style={{
              maxWidth: "800px",
              margin: "30px auto",
              marginTop: "80px",
            }}
          ></div>
          <div
            className="container"
            style={{
              maxWidth: "800px",
              margin: "30px auto",
              marginTop: "80px",
            }}
          ></div>
        </section>
      </div>

      <section
        className="section is-small"
        style={{ backgroundColor: "#FCD136", borderRadius: "14px" }}
        // id="newsletter-subscribe"
      >
        <div
          className="container"
          style={
            {
              // minHeight: "325px",
              // display: "flex",
              // flexWrap: "wrap",
              // justifyContent: "center",
            }
          }
        >
          {" "}
          <h2 className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              FEATURED DESIGNERS
            </span>
            <span
              style={{
                color: "#fff",
              }}
            >
              .
            </span>
          </h2>
          {/* <p
            style={{
              fontSize: "32px",
              fontWeight: "800",
              lineHeight: "1.7",
              marginBottom: "65px",
              textTransform: "uppercase",
              textAlign: "center",
              // color: "#fec600",
            }}
          >
            Featured Travel Designers
          </p> */}
          {/* <button
            className="button"
            onClick={() => {
              getRandomDesigners()
            }}
          >
            test
          </button> */}
          <div
            className="featured-designer-container"
            style={{
              marginTop: "70px",
            }}
          >
            <FetchAsyncDesignerCards />
          </div>
          {/* <div
            style={{
              width: "178px",
              margin: "0 auto",
              marginTop: "30px",
            }}
          >
            <button
              className="button is-warning"
              onClick={() => {
                navigate("/travel-designers")
              }}
              style={{ backgroundColor: "white" }}
            >
              View All Designers
            </button>
          </div> */}
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <a
            style={{
              color: "#4a4a4a",
              // float: "right",
              fontWeight: "500",
              textDecoration: "underline",
              textDecorationColor: "white",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => {
              navigate("/become-a-trip-designer")
            }}
          >
            Become a trip designer? learn how.
          </a>
        </div> */}
      </section>

      <section
        className="section is-small"
        style={{
          paddingLeft: "0px",
          paddingRight: "0px",

          borderRadius: "0px",
          //minHeight: "100vh",
          backgroundColor: "#fff",
          // borderLeft: "1px solid #fec600",
          //borderRight: "1px solid #fec600",
          //backgroundColor: "#f9f9f9",
        }}
        id="designerfaq"
      >
        <div className="container">
          <p className="section-header">
            <span
              style={{
                color: "#4a4a4a",
              }}
            >
              FAQs
            </span>
            <span
              style={{
                color: "#fec600",
              }}
            >
              .
            </span>
          </p>

          {/* <p style={{ fontSize: "37px", fontWeight: "300" }}>Designer</p> */}
          {faqWrapper(
            <Accordion
              header={"So, who exactly is a travel designer?"}
              data={
                "Travel designers are carefully selected travel enthusiasts who have acquired unique-knowledge over their years of either traveling or living in a particular destination. They love doing research to ensure you the best trip based on your requirements."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"How are the designers vetted?"}
              data={
                "Every designer goes through a thorough evaluation process that involves designing a custom travel plan with specific traveller requirements."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"When do I get paid?"}
              data={
                "Once the customer is satisfied with the travel plan and the job is finalized, the money is transferred directly into your account in 48 - 96hrs."
              }
            />
          )}
          {faqWrapper(
            <Accordion
              header={"How much does it cost?"}
              data={
                "Our pricing is simple & transparent. Joining voyayge as a travel designer is free. There is no listing or transaction fee and you keep 75% of the itinerary design fee."
              }
            />
          )}
          <div
            style={{ background: "", padding: "00px 0px", marginTop: "40px" }}
            className=""
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "400",
                textAlign: "right",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
                color: "rgba(74,74,74,0.5)",
              }}
            >
              More questions? <br></br>{" "}
              <span
                style={{
                  fontSize: "22px",
                  fontWeight: "400",
                  textAlign: "right",
                  textTransform: "none",
                  color: "rgba(74,74,74,0.8)",
                  // marginBottom: "17px",
                }}
              >
                We'd be glad to answer{" "}
              </span>
              <br></br>
              {/* <span style={{ fontSize: "47px", fontWeight: "800" }}>
                Get In Touch
              </span>{" "} */}
              <p
                className="link"
                style={{
                  fontSize: "45px",
                  fontWeight: "800",
                  textAlign: "right",
                  marginBottom: "50px",
                  color: "#fec600",
                  lineHeight: "1.0",
                  marginTop: "15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/contact")
                }}
              >
                <span
                  style={{
                    color: "rgba(74,74,74,0.8)",
                  }}
                >
                  GET<br></br>IN TOUCH
                </span>
                <span
                  style={{
                    color: "#fec600",
                  }}
                >
                  .
                </span>
              </p>{" "}
            </p>
          </div>
        </div>
        {/* <div
          style={{
            maxWidth: "400px",
            margin: "30px auto",
            marginTop: "60px",
          }}
        >
          <button
            className="button is-medium is-warning"
            style={{ width: "100%" }}
            onClick={e => {
              const authUser = localStorage.getItem("authUser")
              if (authUser) {
                // If logged in, redirect to the App home page.
                navigate(ROUTES.DESIGNER_SIGNUP)
              } else navigate(ROUTES.SIGNUP_AS_DESIGNER)
            }}
          >
            Get started as a designer
          </button>
        </div> */}
      </section>
    </>
  )
}

export default BecomeADesigner
